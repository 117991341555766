<template>
  <div>
    <TitleMore
      :title="['灾害管理']"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard trainCard">
      <p style="
font-size: 20px;
font-weight: bold;
color: #555454;
line-height: 48px;
text-align:center">{{introData.title}}</p>
      <TitleLine width="200px"></TitleLine>
      <div class="trainContentCard" v-html="introData.text">
        
      </div>
    </div>
    <TitleMore
      :title="['工作动态']"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard">
      <div
        class="itemText"
		    style="margin-bottom: 10px;"
        v-for="item in ListData"
        :key="item.id"
        @click="$goto('/detailShow',{id:item.id})"
      >
        <p>
          <img
            src="../../assets/img/list-little.png"
            alt=""
          >
          <span class="itemTextContent">{{item.title}}</span>
        </p>
        <p class="itemTextTime">{{item.publishDate}}</p>
      </div>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
export default {
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      ListData: [],
      introData:{}
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },

  mounted() {
    this.getList();
    this.getIntro()
  },

  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getList() {
      apiUrls
        .selfDynamicList({ node: "zhglxgxw", page: this.page - 1, size: this.size })
        .then((res) => {
          this.ListData = res.results.data;
          this.total = res.results.pager.totalElements;
        });
    },
    getIntro() {
      apiUrls.trainNum({ node: "zhgljj" }).then((res) => {
        this.introData = res.results.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.trainCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .trainContentCard {
    width: 1038px;
    height: auto;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0px 0px 18px 0px #fad5d6;
    font-size: 16px;
    color: #666666;
    line-height: 36px;
    padding: 20px 40px;
    background: url("../../assets/img/bg-left.png") no-repeat;
    background-size: 20% 130%;
  }
}
.itemTextContent {
	&:hover {
		color: #d1292e;
	}
}
</style>